import Link from 'next/link';
import Image from 'next/image';
import { FC } from 'react';

interface NavLinkProps {
  href: string;
  isPage: boolean;
  activeIcon: any;
  inActiveIcon: any;
  name: string;
  as?: string;
  customStyle?: any;
  id?: string;
}

const NavLink: FC<NavLinkProps> = ({ href, isPage, activeIcon, inActiveIcon, name, as, customStyle, id }) => {
  const imageSize = as ? 24 : 18;

  const focusedPage = (check: boolean, trueValue: any, falseValue: any): any => {
    return check ? trueValue : falseValue;
  };

  return (
    <Link href={href} as={as ? as : href} passHref>
      <a id={id ? id : undefined} style={customStyle ? customStyle : {}} className={focusedPage(isPage, 'active', '')}>
        <span className={'icon' + ` icon-${href.toLowerCase().replace('/', '')}`}>
          {activeIcon && inActiveIcon ? focusedPage(isPage, activeIcon, inActiveIcon)() : null}
        </span>
        <span className={'link-text'}>{name}</span>
      </a>
    </Link>
  );
};

export default NavLink;
