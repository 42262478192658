import { FC, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import logo from '../../../public/logo.svg';
import party_cane_purple from '../../../public/icons/party_cane_purple.svg';
import party_cane_gray from '../../../public/icons/party_cane_gray.svg';
import party_ball_purple from '../../../public/icons/party_ball_purple.svg';
import party_ball_gray from '../../../public/icons/party_ball_gray.svg';
import stats_gray from '../../../public/icons/stats_gray.svg';
import stats_purple from '../../../public/icons/stats_purple.svg';
import account_purple from '../../../public/icons/account_purple.svg';
import account_gray from '../../../public/icons/account_gray.svg';
import guest_list_purple from '../../../public/icons/guest_list_purple.svg';
import guest_list_gray from '../../../public/icons/guest_list_gray.svg';
import crew_purple from '../../../public/icons/crew_purple.svg';
import crew_gray from '../../../public/icons/crew_gray.svg';
import CrowdIcon from '../../../public/icons/crowd.svg';
import MembershipIcon from '../../../public/icons/membership.svg';
import { useTranslation } from 'react-i18next';
import { Language, useGetSignedUserDataQuery, UserPageRole, useUpdateUserMutation } from '../../../graphql/types';
import NavLink from './NavLink';

interface NavLinksProps {
  isRootAdmin: boolean;
  isSideNav?: boolean;
  isVenue?: boolean;
  venueId?: string;
}

const NavMenu: FC<NavLinksProps> = ({ isSideNav, isVenue, venueId, isRootAdmin }) => {
  const router = useRouter();
  const { pathname } = router;
  const { t } = useTranslation('page');
  const isEventPage = pathname.includes('/events');
  const isVenueEvents = pathname.includes('/pages/[id]');
  const isVenueStatistics = pathname.includes('/pages/[id]/statistics');
  const isAccountPage = pathname.includes('accounts');
  const isVenuePage = pathname.includes('/pages');
  const isGuestList = pathname.includes('/guestlist');
  const isMembership = pathname.includes('/membership');
  const isCrowd = pathname.includes('/crowd');
  const { data } = useGetSignedUserDataQuery();
  const [selectedVenueAccessPageIndex, setSelectedVenueAccessPageIndex] = useState(0);
  const [selectedPage, setSelectedPage] = useState<{
    id: string;
    name: string;
  }>({
    id: '',
    name: '',
  });

  const signedInUser = () => data?.signedInUser;

  useEffect(() => {
    if (typeof window !== undefined) {
      setSelectedPage({
        id: localStorage.getItem('page_id') || '',
        name: localStorage.getItem('page_name') || '',
      });
    }
  }, [localStorage.getItem('page_id'), localStorage.getItem('page_name')]);

  const venueAccess = () => signedInUser()?.venueAccess;

  useEffect(() => {
    const index = venueAccess()?.findIndex(item => {
      return item.venue?.id == selectedPage.id;
    });

    if (index !== undefined) {
      setSelectedVenueAccessPageIndex(index);
    }
  }, [selectedPage, data]);

  const canAccessVenue = () => {
    const access = venueAccess();
    return access && access.length > 0;
  };

  const hasSelectedVenue = () => selectedVenueAccessPageIndex !== null && selectedVenueAccessPageIndex > -1;

  const canEditVenue = () => {
    const access = venueAccess();
    if (!hasSelectedVenue()) return false;
    if (!access) return false;

    const role = access[selectedVenueAccessPageIndex]?.role;
    if (!role) return false;

    return access && access.length > 0 && role.includes(UserPageRole.Admin);
  };

  const authMiddleware = () => {
    // ToDo: find the problem why it crashes in prod
    // if (!venueAccess() || !hasSelectedVenue()) return;
    // const protectedPages = ['/events', '/pages', '/membership', '/crowd'];
    // const isProtectedPage = protectedPages.some(p => pathname.includes(p));
    //
    // if (isProtectedPage && !canEditVenue()) {
    //   router.push('/guestlist');
    // }
  };

  useEffect(() => {
    authMiddleware();
  }, [pathname, venueId, selectedVenueAccessPageIndex]);

  return (
    <div className="nav-menu nav-links">
      {isVenue ? (
        <>
          <NavLink
            href={'/pages/[id]'}
            isPage={isVenueEvents}
            activeIcon={party_cane_purple}
            inActiveIcon={party_cane_gray}
            name={t('venues.overview')}
            as={`/pages/${venueId}`}
            customStyle={{ marginLeft: 0 }}
          />
          <NavLink
            href={'/pages/[id]/statistics'}
            isPage={isVenueStatistics}
            activeIcon={stats_purple}
            inActiveIcon={stats_gray}
            name={t('venues.statistics')}
            as={`/pages/${venueId}/statistics`}
          />
        </>
      ) : (
        <>
          {canAccessVenue() ? (
            <>
              {!canEditVenue() ? (
                <>
                  <NavLink
                    href={'/guestlist'}
                    isPage={isGuestList}
                    activeIcon={guest_list_purple}
                    inActiveIcon={guest_list_gray}
                    name={t('guestlist.guestlist')}
                    id={'guestlist-tab'}
                  />
                </>
              ) : (
                <>
                  <NavLink href={'/events'} isPage={isEventPage} activeIcon={party_cane_purple} inActiveIcon={party_cane_gray} name={t('events.events')} />
                  <NavLink href={'/pages'} isPage={isVenuePage} activeIcon={party_ball_purple} inActiveIcon={party_ball_gray} name={t('venues.venues')} />
                  <NavLink
                    href={'/guestlist'}
                    isPage={isGuestList}
                    activeIcon={guest_list_purple}
                    inActiveIcon={guest_list_gray}
                    name={t('guestlist.guestlist')}
                    id={'guestlist-tab'}
                  />
                  <NavLink
                    href={'/membership'}
                    isPage={isMembership}
                    activeIcon={MembershipIcon}
                    inActiveIcon={MembershipIcon}
                    name={t('membership.membership')}
                  />
                  <NavLink href={'/crowd'} isPage={isCrowd} activeIcon={CrowdIcon} inActiveIcon={CrowdIcon} name={t('crowd.crowd')} />
                </>
              )}
            </>
          ) : (
            <></>
          )}

          {isRootAdmin ? (
            <NavLink href={'/accounts'} isPage={isAccountPage} activeIcon={account_purple} inActiveIcon={account_gray} name={t('accounts.accounts')} />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default NavMenu;
