import {useTranslation} from "react-i18next";
import {useGetVenueLocationQuery} from "../../../../graphql/types";
import {useEffect, useMemo, useState} from "react";
import Weather01nIcon from "../../../../public/icons/weather_01n.svg";
import Weather01dIcon from "../../../../public/icons/weather_01d.svg";
import Weather02Icon from "../../../../public/icons/weather_02.svg";
import Weather03Icon from "../../../../public/icons/weather_03.svg";
import Weather04Icon from "../../../../public/icons/weather_04.svg";
import Weather09Icon from "../../../../public/icons/weather_09.svg";
import Weather10Icon from "../../../../public/icons/weather_10.svg";
import Weather11Icon from "../../../../public/icons/weather_11.svg";
import Weather13Icon from "../../../../public/icons/weather_13.svg";
import Weather50Icon from "../../../../public/icons/weather_50.svg";

const weatherIcons = {
  '01n': Weather01nIcon,
  '01d': Weather01dIcon,
  '02d': Weather02Icon,
  '02n': Weather02Icon,
  '03d': Weather03Icon,
  '03n': Weather03Icon,
  '04d': Weather04Icon,
  '04n': Weather04Icon,
  '09d': Weather09Icon,
  '09n': Weather09Icon,
  '10d': Weather10Icon,
  '10n': Weather10Icon,
  '11d': Weather11Icon,
  '11n': Weather11Icon,
  '13d': Weather13Icon,
  '13n': Weather13Icon,
  '50d': Weather50Icon,
  '50n': Weather50Icon,
}

interface WeatherResponse {
  current: {
    temp: number,
    weather: [{
      icon: string,
    }]
  }
  daily: [{
    dt: number,
    weather: [{
      icon: string,
    }],
    temp: {
      max: number,
    }
  }]
}

const WeatherCard = () => {
  const {t} = useTranslation('components')
  const [selectedPage, setSelectedPage] = useState<{
    id: string;
    name: string;
  }>({
    id: '',
    name: '',
  });

  useEffect(() => {
    if (typeof window !== undefined) {
      setSelectedPage({
        id: localStorage.getItem('page_id') || '',
        name: localStorage.getItem('page_name') || '',
      });
    }
  }, [localStorage.getItem('page_id'), localStorage.getItem('page_name')]);

  const {data: locationRes} = useGetVenueLocationQuery({variables: {pageId: selectedPage.id}});

  const location = useMemo(() => {
    return locationRes?.venue?.location;
  }, [locationRes])

  const city = useMemo(() => {
    return locationRes?.venue?.city;
  }, [locationRes])

  const weatherCall = useMemo( () => {
    if(!location) return;
    return fetch(`/api/weather?lat=${location.latitude}&lon=${location.longitude}`).then(res => res.json())
  }, [location])


  const [weather, setWeather] = useState<WeatherResponse>();
  useEffect( () => {
    weatherCall?.then(res => setWeather(res))
  }, [weatherCall])

  const forecast = () => weather?.daily?.filter((_,i) => i  !== 0 && i < 7) || [];

  return weather && weather?.current ? (
    <div className="sidebar-card weather-card">
      <div className="sidebar-card-title">
        <span className={'weather-icon'}>
          {weather?.current?.weather[0]?.icon && weatherIcons[weather?.current?.weather[0]?.icon]()}
        </span>
        <div className={'flex items-center justify-between'}>
          <span className={'weather-city flex flex-col'}>
            <b>{t('sidebar.weather.today')}</b>
            {city}
          </span>
          <span className={'weather-temp'}>
            {Math.round(weather?.current?.temp)}°C
          </span>
        </div>

      </div>
      <div className="sidebar-card-content flex">
        {forecast().map((w) => <div key={w?.dt}>
          <span className={'weather-day'}>
             {new Date(w?.dt * 1000).toLocaleString(
               'default', {weekday: 'short'}
             )}
          </span>
          <span className={'weather-icon'}>
             {w?.weather[0]?.icon && weatherIcons[w?.weather[0]?.icon]()}
          </span>
          <span className={'weather-temp'}>
            {Math.round(w.temp.max)}
          </span>
        </div>)}
      </div>
    </div>
  ) : (<></>)
}

export default WeatherCard
