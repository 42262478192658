import {Language, useUpdateUserMutation} from "../../../graphql/types";
import {FC} from "react";
import {useTranslation} from "react-i18next";

interface LanguageProps {}

const LanguageSwitch: FC<LanguageProps> = () => {
  const [updateUser] = useUpdateUserMutation();
  const { i18n } = useTranslation();

  return(
    <div className="language-switch">
      <div
        onClick={async () => {
          i18n.changeLanguage('en');
          localStorage.setItem('lng', 'en');
          await updateUser({
            variables: {
              input: {
                language: Language.English,
              },
            },
          });
        }}>
        <span className={`${i18n.language === 'en' ? 'active' : ''}`}>EN</span>
      </div>
      <div
        onClick={async () => {
          i18n.changeLanguage('de');
          localStorage.setItem('lng', 'de');
          await updateUser({
            variables: {
              input: {
                language: Language.German,
              },
            },
          });
        }}>
        <span className={`${i18n.language === 'de' ? 'active' : ''}`}>DE</span>
      </div>
    </div>
  )
}

export default LanguageSwitch
