import { useEffect } from 'react';

export const useOutsideTouch = (ref, setOpen) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !event.composedPath().includes(document.getElementById('profile-cell'))) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
