import { mainPages } from '../customTypes';

export const checkPage = (page: mainPages) => {
  const isEvents = page === 'Events';
  const isGuests = page === 'Guests';
  const isVenues = page === 'Venues';
  const isAccounts = page === 'Accounts';
  const isFestivals = page === 'Festivals';
  const isPromoters = page === 'Promoters';
  const isGuestList = page === 'Guest List';
  const isCrew = page === 'Crew';

  return {
    isEvents,
    isGuests,
    isVenues,
    isAccounts,
    isFestivals,
    isPromoters,
    isGuestList,
    isCrew,
  };
};
