import { FC, useEffect, useState } from 'react';
import { useGetSignedUserDataQuery } from '../../../graphql/types';

import NavMenu from './NavMenu';
import Profile from './Profile';
import { SideNav } from './SideNav';
import NavLink from './NavLink';

import CogIcon from '../../../public/icons/cog.svg';
import CrewIcon from '../../../public/icons/crew_gray.svg';
import EditPageIcon from '../../../public/icons/edit_page.svg';
import TicketIcon from '../../../public/icons/ticket.svg';
import LogoutIcon from '../../../public/icons/log_out_gray.svg';
import LanguageSwitch from './LanguageSwitch';
import apolloClient from '../../../graphql/apollo-client';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
  const { data } = useGetSignedUserDataQuery({
    fetchPolicy: 'no-cache',
  });

  const router = useRouter();
  const [visible, setVisible] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>(data?.signedInUser && data?.signedInUser.displayName ? data?.signedInUser.displayName : '');
  const [profilePicture, setProfilePicture] = useState<string>('');
  const [venueName, setVenueName] = useState('');
  const [isRootAdmin, setIsRootAdmin] = useState<boolean>(data?.signedInUser && data?.signedInUser.isRootAdmin ? data?.signedInUser.isRootAdmin : false);
  const [selectedPage, setSelectedPage] = useState<{
    id: string;
    name: string;
  }>({
    id: '',
    name: '',
  });

  useEffect(() => {
    if (data?.signedInUser?.isRootAdmin) {
      localStorage.setItem('is_root_admin', 'true');
    }
  }, [data]);

  useEffect(() => {
    if (typeof window !== undefined) {
      setSelectedPage({
        id: localStorage.getItem('page_id') || '',
        name: localStorage.getItem('page_name') || '',
      });

      setProfilePicture(localStorage.getItem('user_avatar') || '');
      setDisplayName(localStorage.getItem('user_name') || '');
      setIsRootAdmin(Boolean(localStorage.getItem('is_root_admin')) || false);
    }
  }, [
    localStorage.getItem('page_id'),
    localStorage.getItem('page_name'),
    localStorage.getItem('user_avatar'),
    localStorage.getItem('user_name'),
    localStorage.getItem('is_root_admin'),
  ]);

  useEffect(() => {
    if (
      data &&
      data.signedInUser &&
      data.signedInUser.venueAccess &&
      data.signedInUser.venueAccess[0] &&
      data.signedInUser.venueAccess[0].venue &&
      selectedPage.id === ''
    ) {
      setSelectedPage({ id: data.signedInUser.venueAccess[0].venue.id, name: data.signedInUser.venueAccess[0].venue.name });
      setVenueName(data.signedInUser.venueAccess[0].venue.name);
    } else if (data && data.signedInUser && data.signedInUser.venueAccess) {
      const foundVenue = data.signedInUser.venueAccess.find(venue => venue.venue?.id == selectedPage.id);
      setSelectedPage({ ...selectedPage, name: foundVenue && foundVenue.venue ? foundVenue.venue.name : '' });
    }
    if (
      data &&
      data.signedInUser &&
      data.signedInUser.venueAccess &&
      data.signedInUser.venueAccess[0] &&
      data.signedInUser.venueAccess[0].venue &&
      selectedPage.id === ''
    ) {
      setSelectedPage({ id: data.signedInUser.venueAccess[0].venue.id, name: data.signedInUser.venueAccess[0].venue.name });
    }
  }, [data]);

  const handleLogOut = async () => {
    await fetch('/api/logout', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async () => {
        setSelectedPage({
          id: '',
          name: '',
        });

        localStorage.setItem('token', '');
        localStorage.setItem('page_id', '');
        localStorage.setItem('page_name', '');
        localStorage.setItem('user_name', '');
        localStorage.setItem('user_avatar', '');
        localStorage.setItem('is_root_admin', '');

        apolloClient.clearStore();
        apolloClient.cache.reset();

        await router.replace('/login/with-email');
      })
      .catch(() => {});
  };

  const { t } = useTranslation('page');
  return (
    <div className="sidebar-container">
      <div className="sidebar-menu">
        <div className={'sidebar-logo'}>
          <svg className={'sm'} width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.204987 21.2097L3.98054 11.4884C4.1163 11.1377 4.3365 10.8259 4.62162 10.5807C4.90381 10.3379 5.24269 10.1703 5.60689 10.0933C5.96955 10.0169 6.34569 10.0341 6.69988 10.1432C7.0564 10.2536 7.37942 10.4519 7.63917 10.7199L13.9567 17.1912C14.2187 17.4612 14.4104 17.7914 14.515 18.1528C14.6205 18.5162 14.6363 18.8998 14.561 19.2707C14.4864 19.64 14.3227 19.9854 14.0841 20.277C13.8485 20.5665 13.5441 20.7923 13.1987 20.9339L3.69547 24.7974C3.20602 24.9942 2.66887 25.04 2.15319 24.9288C1.63468 24.818 1.16062 24.5561 0.790894 24.176C0.416679 23.791 0.161669 23.3061 0.0565403 22.7796C-0.0505002 22.2523 -0.00492781 21.7055 0.187909 21.2032L0.204987 21.2097Z"
              fill="white"
            />
            <path
              d="M20.498 15.3681C20.7712 15.1301 20.9413 14.7953 20.9724 14.4343C21.0035 14.0733 20.8933 13.7143 20.6648 13.433C20.2538 12.9391 19.7442 12.5364 19.1686 12.2507C18.5955 11.9667 17.9686 11.8077 17.3294 11.7843C16.8261 11.7385 16.3187 11.798 15.8397 11.9591C15.358 12.1209 14.9148 12.3803 14.5378 12.721C14.2931 12.9826 14.1582 13.3282 14.1612 13.6864C14.1641 14.0446 14.3046 14.388 14.5536 14.6456C14.6755 14.771 14.821 14.8711 14.9817 14.9401C15.1425 15.0091 15.3153 15.0456 15.4902 15.0476C15.8283 14.9872 16.1497 14.856 16.4334 14.6626C16.8032 14.5124 17.2108 14.4821 17.5987 14.5759C17.9884 14.6709 18.339 14.8843 18.6023 15.1868C18.7141 15.325 18.852 15.4398 19.0083 15.5244C19.1636 15.6086 19.3342 15.6606 19.5101 15.6773C19.6859 15.694 19.8633 15.6751 20.0316 15.6216C20.2025 15.5692 20.3611 15.483 20.498 15.3681Z"
              fill="white"
            />
            <path
              d="M12.0741 10.1049C12.9219 9.15675 13.4791 7.98478 13.6794 6.72872C13.8821 5.46845 13.7213 4.17649 13.2157 3.00441C12.892 2.1976 12.3592 1.49159 11.6721 0.958994C10.9926 0.432239 10.1823 0.100689 9.32845 0C9.15152 0.000240131 8.97643 0.0359334 8.81352 0.104977C8.65061 0.174021 8.50319 0.275016 8.37997 0.401989C8.1281 0.662408 7.9873 1.01052 7.9873 1.37281C7.9873 1.7351 8.1281 2.08321 8.37997 2.34363C8.50324 2.47054 8.65066 2.57149 8.81356 2.64053C8.97646 2.70957 9.15153 2.7453 9.32845 2.74562C9.66933 2.87573 9.9784 3.07729 10.2349 3.33677C10.4938 3.59858 10.6947 3.91189 10.8247 4.25636C11.0969 4.95489 11.1617 5.71711 11.0113 6.45154C10.8647 7.18192 10.5086 7.85383 9.98661 8.38529C9.76369 8.67102 9.66058 9.03222 9.69907 9.39258C9.73755 9.75293 9.91462 10.0842 10.1929 10.3164C10.3289 10.4292 10.4862 10.5134 10.6555 10.564C10.8248 10.6145 11.0026 10.6304 11.1781 10.6107C11.3544 10.5906 11.525 10.5355 11.6797 10.4487C11.8345 10.3618 11.9703 10.2449 12.0793 10.1049H12.0741Z"
              fill="white"
            />
            <path
              d="M25.6637 8.23522C25.6644 8.83976 25.4286 9.4206 25.0068 9.85369C24.8019 10.0658 24.5563 10.2344 24.2848 10.3496C24.0133 10.4648 23.7214 10.5241 23.4265 10.5241C23.1315 10.5241 22.8396 10.4648 22.5681 10.3496C22.2966 10.2344 22.0511 10.0658 21.8461 9.85369C21.4262 9.41956 21.1914 8.83921 21.1914 8.23522C21.1914 7.63122 21.4262 7.05087 21.8461 6.61675C22.0511 6.40467 22.2966 6.23602 22.5681 6.12083C22.8396 6.00565 23.1315 5.94629 23.4265 5.94629C23.7214 5.94629 24.0133 6.00565 24.2848 6.12083C24.5563 6.23602 24.8019 6.40467 25.0068 6.61675C25.4285 7.04991 25.6642 7.6307 25.6637 8.23522Z"
              fill="white"
            />
            <path
              d="M17.502 8.32625C18.2223 7.65738 18.7919 6.84278 19.173 5.93665C19.5579 5.0227 19.7464 4.03801 19.7261 3.04652C19.7262 2.68392 19.5848 2.33559 19.3319 2.0757C19.2093 1.94795 19.0622 1.84631 18.8993 1.77687C18.7364 1.70743 18.5612 1.67163 18.3841 1.67163C18.2071 1.67163 18.0318 1.70743 17.8689 1.77687C17.7061 1.84631 17.5589 1.94795 17.4363 2.0757C17.1833 2.33551 17.0419 2.68389 17.0422 3.04652C17.0625 3.62455 16.9651 4.20067 16.7558 4.73987C16.5479 5.27594 16.2317 5.7634 15.827 6.1718C15.5469 6.40119 15.3681 6.73143 15.3291 7.09138C15.2884 7.45203 15.3897 7.81436 15.6116 8.1016C15.7197 8.24197 15.8548 8.35935 16.0088 8.44687C16.1629 8.5344 16.3329 8.5903 16.5088 8.61132C16.6843 8.63177 16.8622 8.6166 17.0317 8.56672C17.2012 8.51683 17.3589 8.43325 17.4954 8.32099L17.502 8.32625Z"
              fill="white"
            />
          </svg>

          <svg className={'lg'} width="158" height="25" viewBox="0 0 158 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M38.8157 8.07754C37.4213 8.03242 36.058 8.49556 34.9797 9.38072V8.43355H31.9109V24.9939H34.9797V19.3319C36.0662 20.1997 37.4257 20.6532 38.8157 20.6115C40.3751 20.4632 41.8233 19.7389 42.8772 18.5801C43.9312 17.4212 44.5152 15.911 44.5152 14.3445C44.5152 12.778 43.9312 11.2678 42.8772 10.109C41.8233 8.95007 40.3751 8.22576 38.8157 8.07754ZM38.281 17.8869C37.3727 17.8406 36.5169 17.4472 35.8904 16.7879C35.2639 16.1287 34.9146 15.254 34.9146 14.3445C34.9146 13.435 35.2639 12.5603 35.8904 11.9011C36.5169 11.2418 37.3727 10.8484 38.281 10.8021C39.1691 10.8767 39.9969 11.2823 40.6001 11.9384C41.2034 12.5945 41.5382 13.4532 41.5382 14.3445C41.5382 15.2358 41.2034 16.0945 40.6001 16.7506C39.9969 17.4067 39.1691 17.8123 38.281 17.8869Z"
              fill="white"
            />
            <path
              d="M56.2433 8.43241V9.37958C55.165 8.49431 53.8017 8.03115 52.4073 8.07639C50.8479 8.22461 49.3997 8.94892 48.3457 10.1078C47.2918 11.2667 46.7078 12.7769 46.7078 14.3434C46.7078 15.9098 47.2918 17.42 48.3457 18.5789C49.3997 19.7378 50.8479 20.4621 52.4073 20.6103C53.7972 20.652 55.1567 20.1985 56.2433 19.3308V20.2504H59.3081V8.42714L56.2433 8.43241ZM52.9446 17.891C52.1212 17.933 51.3089 17.6869 50.6473 17.1949C49.9857 16.703 49.5162 15.9959 49.3194 15.1953C49.1226 14.3946 49.2108 13.5505 49.569 12.8079C49.9271 12.0652 50.5328 11.4706 51.2818 11.1261C52.0309 10.7816 52.8765 10.7088 53.6734 10.9203C54.4703 11.1317 55.1686 11.6141 55.6484 12.2846C56.1282 12.9551 56.3594 13.7718 56.3023 14.5943C56.2452 15.4167 55.9033 16.1937 55.3355 16.7914C54.7086 17.4503 53.8529 17.8438 52.9446 17.891Z"
              fill="white"
            />
            <path
              d="M69.5567 8.2678C68.722 8.26485 67.9007 8.47687 67.1718 8.88343C66.4429 9.28999 65.8309 9.87741 65.3949 10.5891V8.43332H62.3235V20.2565H65.3923V16.4468C65.3923 13.1534 66.6941 11.3773 69.5304 11.3773H70.2043L70.3685 8.36763C70.1063 8.28693 69.8319 8.25314 69.558 8.2678H69.5567Z"
              fill="white"
            />
            <path
              d="M81.36 17.1294C80.7672 17.4604 80.1078 17.6548 79.4302 17.6983C78.4069 17.6983 77.7329 17.0821 77.7329 15.6607V10.9945H81.5229V8.43805H77.7316V4.85693H74.6642V8.43805H72.176V10.9945H74.6628V15.8985C74.6628 19.1446 76.4757 20.6133 79.22 20.6133C80.2889 20.6028 81.3342 20.2983 82.2415 19.7332L81.36 17.1294Z"
              fill="white"
            />
            <path
              d="M93.1117 8.40936L89.9036 15.967L86.8375 8.40936H83.4219L88.2339 19.8766L86.0729 25H89.4885L96.5325 8.41462L93.1117 8.40936Z"
              fill="white"
            />
            <path
              d="M112.97 8.07796C112.162 8.04745 111.358 8.2074 110.623 8.54491C109.888 8.88242 109.242 9.38803 108.739 10.0209C108.315 9.40591 107.745 8.90617 107.08 8.56692C106.415 8.22767 105.676 8.05962 104.929 8.07796C104.291 8.04959 103.655 8.15274 103.059 8.38101C102.463 8.60928 101.92 8.95777 101.465 9.40479V8.43398H98.3882V20.2572H101.457V14.5702C101.457 12.0821 102.48 10.9457 104.107 10.9457C104.43 10.9487 104.749 11.0192 105.043 11.1528C105.338 11.2865 105.601 11.4802 105.816 11.7216C106.03 11.963 106.192 12.2468 106.291 12.5546C106.39 12.8623 106.423 13.1874 106.389 13.5088V20.2611H109.456V14.5702C109.456 12.0821 110.527 10.9457 112.153 10.9457C112.475 10.9498 112.793 11.0211 113.086 11.1549C113.379 11.2888 113.641 11.4823 113.855 11.7232C114.069 11.964 114.231 12.2469 114.329 12.5537C114.427 12.8605 114.461 13.1844 114.427 13.5048V20.2572H117.472V12.8637C117.505 12.2502 117.412 11.6364 117.201 11.0595C116.989 10.4826 116.663 9.95458 116.242 9.50729C115.82 9.06 115.313 8.70273 114.75 8.45707C114.186 8.21141 113.579 8.08244 112.965 8.07796H112.97Z"
              fill="white"
            />
            <path
              d="M129.5 8.43241V9.37958C128.421 8.49431 127.058 8.03115 125.664 8.07639C124.104 8.22461 122.656 8.94892 121.602 10.1078C120.548 11.2667 119.964 12.7769 119.964 14.3434C119.964 15.9098 120.548 17.42 121.602 18.5789C122.656 19.7378 124.104 20.4621 125.664 20.6103C127.054 20.652 128.413 20.1985 129.5 19.3308V20.2504H132.566V8.42714L129.5 8.43241ZM126.2 17.891C125.376 17.933 124.564 17.6869 123.902 17.1949C123.241 16.703 122.771 15.9959 122.574 15.1953C122.378 14.3946 122.466 13.5505 122.824 12.8079C123.182 12.0652 123.788 11.4706 124.537 11.1261C125.286 10.7816 126.132 10.7088 126.928 10.9203C127.725 11.1317 128.424 11.6141 128.903 12.2846C129.383 12.9551 129.614 13.7718 129.557 14.5943C129.5 15.4167 129.158 16.1937 128.591 16.7914C127.964 17.4503 127.108 17.8438 126.2 17.891Z"
              fill="white"
            />
            <path
              d="M143.835 17.1294C143.242 17.4604 142.583 17.6548 141.906 17.6983C140.883 17.6983 140.209 17.0821 140.209 15.6607V10.9945H143.999V8.43805H140.209V4.85693H137.14V8.43805H134.656V10.9945H137.143V15.8985C137.143 19.1446 138.956 20.6133 141.7 20.6133C142.769 20.6041 143.814 20.3009 144.722 19.7371L143.835 17.1294Z"
              fill="white"
            />
            <path
              d="M157.662 14.1197C157.558 12.9686 157.122 11.8725 156.406 10.965C155.691 10.0575 154.726 9.37782 153.631 9.00873C152.536 8.63964 151.357 8.5971 150.238 8.88629C149.119 9.17549 148.108 9.78393 147.329 10.6375C146.55 11.491 146.035 12.5528 145.849 13.6934C145.663 14.8341 145.812 16.0043 146.279 17.0616C146.746 18.1188 147.51 19.0173 148.479 19.6477C149.448 20.2781 150.579 20.6132 151.735 20.612C152.937 20.6716 154.127 20.3513 155.137 19.6965C156.146 19.0416 156.924 18.0856 157.36 16.9638L154.617 16.3714C154.345 16.8879 153.926 17.3122 153.413 17.5905C152.9 17.8688 152.316 17.9885 151.735 17.9346C150.999 17.9094 150.297 17.6162 149.762 17.1102C149.227 16.6041 148.895 15.92 148.829 15.1864H157.662V14.1197ZM151.781 10.8026C152.421 10.7856 153.047 10.9947 153.549 11.3932C154.051 11.7917 154.397 12.3542 154.525 12.982H148.922C149.087 12.3518 149.458 11.7948 149.976 11.3998C150.494 11.0049 151.129 10.7946 151.781 10.8026Z"
              fill="white"
            />
            <path
              d="M0.204987 21.2097L3.98054 11.4884C4.1163 11.1377 4.3365 10.8259 4.62162 10.5807C4.90381 10.3379 5.24269 10.1703 5.60689 10.0933C5.96955 10.0169 6.34569 10.0341 6.69988 10.1432C7.0564 10.2536 7.37942 10.4519 7.63917 10.7199L13.9567 17.1912C14.2187 17.4612 14.4104 17.7914 14.515 18.1528C14.6205 18.5162 14.6363 18.8998 14.561 19.2707C14.4864 19.64 14.3227 19.9854 14.0841 20.277C13.8485 20.5665 13.5441 20.7923 13.1987 20.9339L3.69547 24.7974C3.20602 24.9942 2.66887 25.04 2.15319 24.9288C1.63468 24.818 1.16062 24.5561 0.790894 24.176C0.416679 23.791 0.161669 23.3061 0.0565403 22.7796C-0.0505002 22.2523 -0.00492781 21.7055 0.187909 21.2032L0.204987 21.2097Z"
              fill="white"
            />
            <path
              d="M20.4975 15.3681C20.7707 15.1301 20.9408 14.7953 20.9719 14.4343C21.0031 14.0733 20.8928 13.7143 20.6644 13.433C20.2533 12.9391 19.7437 12.5364 19.1681 12.2507C18.595 11.9667 17.9681 11.8077 17.3289 11.7843C16.8256 11.7385 16.3182 11.798 15.8392 11.9591C15.3575 12.1209 14.9143 12.3803 14.5373 12.721C14.2926 12.9826 14.1578 13.3282 14.1607 13.6864C14.1636 14.0446 14.3041 14.388 14.5531 14.6456C14.675 14.771 14.8205 14.8711 14.9813 14.9401C15.142 15.0091 15.3148 15.0456 15.4897 15.0476C15.8278 14.9872 16.1492 14.856 16.433 14.6626C16.8027 14.5124 17.2103 14.4821 17.5982 14.5759C17.9879 14.6709 18.3385 14.8843 18.6019 15.1868C18.7136 15.325 18.8515 15.4398 19.0078 15.5244C19.1631 15.6086 19.3337 15.6606 19.5096 15.6773C19.6854 15.694 19.8628 15.6751 20.0312 15.6216C20.202 15.5692 20.3606 15.483 20.4975 15.3681Z"
              fill="white"
            />
            <path
              d="M12.0741 10.1049C12.9219 9.15675 13.4791 7.98478 13.6794 6.72872C13.8821 5.46845 13.7213 4.17649 13.2157 3.00441C12.892 2.1976 12.3592 1.49159 11.6721 0.958994C10.9926 0.432239 10.1823 0.100689 9.32845 0C9.15152 0.000240131 8.97643 0.0359334 8.81352 0.104977C8.65061 0.174021 8.50319 0.275016 8.37997 0.401989C8.1281 0.662408 7.9873 1.01052 7.9873 1.37281C7.9873 1.7351 8.1281 2.08321 8.37997 2.34363C8.50324 2.47054 8.65066 2.57149 8.81356 2.64053C8.97646 2.70957 9.15153 2.7453 9.32845 2.74562C9.66933 2.87573 9.9784 3.07729 10.2349 3.33677C10.4938 3.59858 10.6947 3.91189 10.8247 4.25636C11.0969 4.95489 11.1617 5.71711 11.0113 6.45154C10.8647 7.18192 10.5086 7.85383 9.98661 8.38529C9.76369 8.67102 9.66058 9.03222 9.69907 9.39258C9.73755 9.75293 9.91462 10.0842 10.1929 10.3164C10.3289 10.4292 10.4862 10.5134 10.6555 10.564C10.8248 10.6145 11.0026 10.6304 11.1781 10.6107C11.3544 10.5906 11.525 10.5355 11.6797 10.4487C11.8345 10.3618 11.9703 10.2449 12.0793 10.1049H12.0741Z"
              fill="white"
            />
            <path
              d="M25.6632 8.23522C25.6639 8.83976 25.4281 9.4206 25.0063 9.85369C24.8014 10.0658 24.5558 10.2344 24.2843 10.3496C24.0128 10.4648 23.7209 10.5241 23.426 10.5241C23.131 10.5241 22.8391 10.4648 22.5676 10.3496C22.2961 10.2344 22.0506 10.0658 21.8456 9.85369C21.4257 9.41956 21.1909 8.83921 21.1909 8.23522C21.1909 7.63122 21.4257 7.05087 21.8456 6.61675C22.0506 6.40467 22.2961 6.23602 22.5676 6.12083C22.8391 6.00565 23.131 5.94629 23.426 5.94629C23.7209 5.94629 24.0128 6.00565 24.2843 6.12083C24.5558 6.23602 24.8014 6.40467 25.0063 6.61675C25.428 7.04991 25.6637 7.6307 25.6632 8.23522Z"
              fill="white"
            />
            <path
              d="M17.502 8.32625C18.2223 7.65738 18.7919 6.84278 19.173 5.93665C19.5579 5.0227 19.7464 4.03801 19.7261 3.04652C19.7262 2.68392 19.5848 2.33559 19.3319 2.0757C19.2093 1.94795 19.0622 1.84631 18.8993 1.77687C18.7364 1.70743 18.5612 1.67163 18.3841 1.67163C18.2071 1.67163 18.0318 1.70743 17.8689 1.77687C17.7061 1.84631 17.5589 1.94795 17.4363 2.0757C17.1833 2.33551 17.0419 2.68389 17.0422 3.04652C17.0625 3.62455 16.9651 4.20067 16.7558 4.73987C16.5479 5.27594 16.2317 5.7634 15.827 6.1718C15.5469 6.40119 15.3681 6.73143 15.3291 7.09138C15.2884 7.45203 15.3897 7.81436 15.6116 8.1016C15.7197 8.24197 15.8548 8.35935 16.0088 8.44687C16.1629 8.5344 16.3329 8.5903 16.5088 8.61132C16.6843 8.63177 16.8622 8.6166 17.0317 8.56672C17.2012 8.51683 17.3589 8.43325 17.4954 8.32099L17.502 8.32625Z"
              fill="white"
            />
          </svg>
        </div>

        {/*<CloseSideNav visible={visible} setVisible={setVisible} />*/}
        <Profile
          displayName={displayName}
          setPageS={setSelectedPage}
          pageS={selectedPage}
          pages={data && data.signedInUser ? data.signedInUser.venueAccess : []}
          profilePicURL={profilePicture}
          venueName={venueName}
        />
        <NavMenu isRootAdmin={isRootAdmin} />

        <div className={'nav-links'}>
          <NavLink
            isPage={false}
            activeIcon={EditPageIcon}
            inActiveIcon={EditPageIcon}
            href={selectedPage.id ? `/pages/${selectedPage.id}/settings` : ''}
            name={'Edit Page'}
          />
          <NavLink isPage={false} activeIcon={CrewIcon} inActiveIcon={CrewIcon} href={'/crew'} name={'Crew'} />
          <NavLink isPage={false} activeIcon={CogIcon} inActiveIcon={CogIcon} href={'/profile'} name={t('settings')} />
          <button onClick={handleLogOut}>
            <span className={'icon icon-logout'}>
              <LogoutIcon />
            </span>
            <span className={'link-text'}>Logout</span>
          </button>
        </div>

        <LanguageSwitch />
      </div>
      <SideNav visible={visible} isRootAdmin={isRootAdmin} />
    </div>
  );
};

export default Sidebar;
