import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Avatar } from 'antd';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { UserVenueAccess } from '../../../graphql/types';
import { useOutsideTouch } from '../../utils';
import apolloClient from '../../../graphql/apollo-client';
import CaretDown from '../../../public/icons/caret_down.svg';

interface ProfileProps {
  displayName: string | null | undefined;
  profilePicURL: string | null | undefined;
  pages: UserVenueAccess[] | [] | any;
  pageS: { id: string; name: string };
  setPageS: Dispatch<
    SetStateAction<{
      id: string;
      name: string;
    }>
  >;
  venueName: string;
}

const Profile: FC<ProfileProps> = ({ displayName, profilePicURL, pages }) => {
  const firstName = displayName ? displayName : '';
  const [open, setOpen] = useState<boolean>(false);
  const router = useRouter();
  const [profilePic, setProfilePic] = useState('');
  const venueSelectRef = useRef(null);

  useOutsideTouch(venueSelectRef, setOpen);
  const [selectedPage, setSelectedPage] = useState<{
    id: string;
    name: string;
  }>({
    id: '',
    name: '',
  });

  useEffect(() => {
    if (typeof window !== undefined) {
      setSelectedPage({
        id: localStorage.getItem('page_id') || '',
        name: localStorage.getItem('page_name') || '',
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('page_id', selectedPage.id);
    localStorage.setItem('page_name', selectedPage.name);
  }, [selectedPage]);

  useEffect(() => {
    if (profilePicURL) {
      setProfilePic(profilePicURL);
      localStorage.setItem('user_avatar', profilePicURL);
    }

    if (displayName) {
      localStorage.setItem('user_name', displayName);
    }
  }, [profilePicURL, displayName]);

  const selectedRole = () => {
    const page = pages.find(p => p.venue.id === selectedPage.id);
    return Array.isArray(page?.role) ? page.role.map((e, i) => (i == page.role.length - 1 ? e : `${e} | `)) : '';
  };

  return (
    <div id="profile-cell" className="profile">
      <div className={'profile-inner'}>
        <div className={'profile-avatar'} onClick={() => setOpen(!open)}>
          {profilePic ? <Avatar size="large" src={profilePic} /> : <Avatar size="large">{firstName.charAt(0)}</Avatar>}
        </div>
        <div className={'profile-info'} onClick={() => setOpen(!open)}>
          <h1>
            {selectedPage.name}
            <CaretDown />
          </h1>
          <h3>{selectedRole() || '\u00A0'}</h3>
        </div>

        {open ? (
          <div className="profile-dropdown" ref={venueSelectRef}>
            <div className="pages-container">
              {pages.map(page => (
                <div
                  className="pages-row"
                  key={page.id}
                  onClick={() => {
                    setSelectedPage({ id: page.venue.id, name: page.venue.name });
                    setOpen(false);
                    return router.replace('/guestlist');
                  }}>
                  <div className="page-avatar-container">
                    <div className="ant-avatar-sm">
                      <Image
                        alt="page icon"
                        src={page.venue.logoUrl || 'https://pm-marketing-public.s3.eu-central-1.amazonaws.com/app-icon.png'}
                        layout="fill"
                      />
                    </div>
                  </div>
                  <div className="page-info">{page.venue ? page.venue.name : 'Venue'}</div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Profile;
