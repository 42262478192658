import { FC } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import SidebarRight from './SidebarRight';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <div className={'main-content-container'}>
      <Sidebar />
      <div className="content">{children}</div>
      <SidebarRight />
    </div>
  );
};

export default Layout;
