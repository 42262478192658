import { Dispatch, FC, SetStateAction } from 'react';
import { Button } from 'antd';
import NavMenu from './NavMenu';

interface SideNavProps {
  visible: boolean;
  isRootAdmin: boolean;
}

export const SideNav: FC<SideNavProps> = ({ visible, isRootAdmin }) => {
  return (
    <div className="sidenav" style={{ display: visible ? 'block' : 'none' }}>
      <div className="sidenav-container">
        <NavMenu isSideNav={true} isRootAdmin={isRootAdmin} />
      </div>
    </div>
  );
};

export const CloseSideNav: FC<{ visible: boolean; setVisible: Dispatch<SetStateAction<boolean>> }> = ({ visible, setVisible }) => {
  return (
    <div className="sidenav-close">
      <Button
        onClick={() => setVisible(!visible)}
        icon={
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.32666 15.7822H26.6733" stroke="#171A23" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.32666 8.66663H26.6733" stroke="#171A23" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.32666 22.8977H26.6733" stroke="#171A23" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        }
      />
    </div>
  );
};
