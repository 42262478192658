import dynamic from 'next/dynamic';
import { Suspense, useMemo } from 'react';
import { useRouter } from 'next/router';
import SidebarDefault from '../Sidebars/SidebarDefault';

const getDynamicSidebar = component => dynamic(() => import(`../Sidebars/${component}`));

const SidebarsAvailable = ['SidebarDefault', 'SidebarMembership', 'SidebarCrowd', 'SidebarGuestlist[id]'];

const SidebarRight = () => {
  const router = useRouter();
  const { pathname } = router;

  const ComponentName = () => {
    let name = pathname.replaceAll('/', '');
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return `Sidebar${name}`;
  };

  const DynamicSidebar = useMemo(() => {
    if (SidebarsAvailable.includes(ComponentName())) {
      return getDynamicSidebar(ComponentName());
    }
    return null;
  }, [pathname]);

  return (
    <div className={'sidebar-container sidebar-right'}>
      <Suspense fallback={<SidebarDefault />}>
        {DynamicSidebar && <DynamicSidebar />}
        {!DynamicSidebar && <SidebarDefault />}
      </Suspense>
    </div>
  );
};

export default SidebarRight;
